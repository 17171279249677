import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { css } from 'emotion';
import { darken } from 'polished';
import shortid from 'shortid';

import { Container, Row, Col } from 'react-grid-system';

import LayoutPage from '../components/Layouts/LayoutPage';
import NewsList from '../components/News/NewsList';
import NewsItem from '../components/News/NewsItem';
import { themeVars } from '../config/styles';
import { breakup } from '../config/breakpoints';

const NewsPage = () => {
  // Set the number of inital posts to display
  const [postsToShow, setPostsToShow] = useState(12);

  // Scroll to the top of the page on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cssNews = css`
    padding: 6rem 0;

    ${breakup.medium`padding: 8rem 0;`}
    ${breakup.large`padding: 10rem 0;`}
  `;

  const cssButton = css`
    transition: all 0.3s ease-in-out;
    display: inline-block;
    position: relative;
    border: 0;
    cursor: pointer;

    padding: 1rem 3rem;
    font-size: 1.6rem;
    color: ${themeVars.root.light};
    text-transform: uppercase;

    background: ${themeVars.root.secondary};

    &:focus {
      outline: none;
    }

    &:hover {
      background: ${darken('0.2', themeVars.root.secondary)};
    }
  `;

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }

          wordpressPage(slug: { eq: "news" }, status: { eq: "publish" }) {
            yoast {
              title
              metadesc
              linkdex
              metakeywords
              meta_robots_noindex
              meta_robots_nofollow
              meta_robots_adv
              canonical
              redirect
              opengraph_title
              opengraph_description
              opengraph_image
              twitter_title
              twitter_description
            }

            link
            title

            acf {
              page_hero_title
              page_hero_subtitle
              page_hero_bg {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1440, quality: 75, toFormat: JPG) {
                      tracedSVG
                      sizes
                      src
                      srcSet
                      aspectRatio
                    }
                  }
                }
              }
            }
          }

          allWordpressPost(filter: { status: { eq: "publish" } }) {
            totalCount
            edges {
              node {
                yoast {
                  title
                  metadesc
                  linkdex
                  metakeywords
                  meta_robots_noindex
                  meta_robots_nofollow
                  meta_robots_adv
                  canonical
                  redirect
                  opengraph_title
                  opengraph_description
                  opengraph_image
                  twitter_title
                  twitter_description
                }

                link
                title
                content
                date(formatString: "LL")
                slug
                acf {
                  news_preview
                  news_featured_image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 800, maxHeight: 800) {
                          tracedSVG
                          sizes
                          src
                          srcSet
                          aspectRatio
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          wordpressAcfOptions {
            options {
              options_rescue {
                panel_one_title
                panel_one_label
                panel_one_page {
                  post_name
                }
                panel_two_title
                panel_two_label
                panel_two_page {
                  post_name
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <LayoutPage
            data={data.wordpressPage}
            optionsData={data.wordpressAcfOptions}
            siteMeta={data.site}
          >
            <Container fluid className={cssNews} style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Row nogutter>
                <Col xs={12}>
                  <Row>
                    <Col xs={10} push={{ xs: 1 }}>
                      <NewsList>
                        {data.allWordpressPost.edges.slice(0, postsToShow).map((item) => {
                          // URL must be defined like this to avoid error

                          /** eslint-disable-next prefer-template */
                          return (
                            <NewsItem
                              xs={12}
                              md={6}
                              lg={4}
                              xl={4}
                              imageUrl={
                                item.node.acf.news_featured_image.localFile.childImageSharp.fluid
                              }
                              title={item.node.title}
                              date={item.node.date}
                              content={item.node.acf.news_preview}
                              buttonLink={`/news/${item.node.slug}/`}
                              key={shortid.generate()}
                            />
                          );
                        })}
                      </NewsList>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row nogutter>
                <Col xs={12}>
                  <Row>
                    <Col xs={10} push={{ xs: 1 }} style={{ textAlign: 'center' }}>
                      {postsToShow <= data.allWordpressPost.totalCount && (
                        <button
                          className={cssButton}
                          onClick={() => setPostsToShow(postsToShow + 12)}
                        >
                          Load More
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </LayoutPage>
        );
      }}
    />
  );
};

export default NewsPage;
