import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';

import { themeVars } from '../../config/styles';

const CardDate = ({ children }) => {
  const cssDate = css`
    color: #4e5061;
    font-size: 16px;
    font-family: ${themeVars.root.headerFontFamily};
    margin-bottom: 15px;
  `;

  return <p className={cssDate}>{children}</p>;
};

CardDate.propTypes = {
  children: PropTypes.string.isRequired,
};

export default CardDate;
