import React from 'react';
import { Row } from 'react-grid-system';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const NewsList = ({ children }) => {
  const cssWrapper = css`
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    align-items: stretch;
  `;
  return <Row className={cssWrapper}>{children}</Row>;
};

NewsList.propTypes = {
  /** List of NewsItem components */
  children: PropTypes.node.isRequired,
};

export default NewsList;
