import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { themeVars } from '../../config/styles';
import { breakup } from '../../config/breakpoints';

const CardButton = ({ children, to }) => {
  const cssButton = css`
    transition: all 0.3s, ease-in-out;
    background: none;
    border: none;
    padding: 0;

    color: #512155;
    font-family: ${themeVars.root.headerFontFamily};
    font-size: 1.6rem;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    outline: none;

    ${breakup.large`font-size: 1.8rem;`}

    &:hover {
      color: ${themeVars.root.dark};
    }
  `;

  return (
    <Link to={`${to}`}>
      <button className={cssButton}>{children}</button>
    </Link>
  );
};

CardButton.propTypes = {
  /** the label of the button */
  children: PropTypes.string.isRequired,

  /** The URL for the button */
  to: PropTypes.string.isRequired,
};

export default CardButton;
