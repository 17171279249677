import React from 'react';
import { Col, Visible } from 'react-grid-system';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import TextTruncate from 'react-text-truncate';

import { themeVars } from '../../config/styles';
import { breakup } from '../../config/breakpoints';

import Card from '../Common/Card';
import CardTitle from '../Common/CardTitle';
import CardDate from '../Common/CardDate';
import CardContent from '../Common/CardContent';
import CardButton from '../Common/CardButton';

const NewsItem = ({ xs, sm, md, lg, xl, date, title, content, buttonLink, imageUrl }) => {
  const cssWrapper = css`
    margin-bottom: 50px;

    &:hover {
      h3,
      a {
        color: ${themeVars.root.primary};
      }

      img {
        transform: scale(1.1);
      }
    }
  `;

  const cssImage = css`
    vertical-align: bottom;
    max-width: 100%;
    position: relative;
  `;

  const cssTitleWrap = css`
    display: flex;
    flex-grow: 1;
  `;

  const cssContentWrapper = css`
    min-height: 268px;

    ${breakup.large`
      min-height: 353px;`}
  `;

  return (
    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={cssWrapper}>
      <div className="news-item">
        <Link to={buttonLink}>
          <Img className={cssImage} fluid={imageUrl} alt="" />
        </Link>

        <Card>
          <div className={cssContentWrapper}>
            <CardDate>{date}</CardDate>
            <Link to={`${buttonLink}`}>
              <div className={cssTitleWrap}>
                <CardTitle>{title}</CardTitle>
              </div>
            </Link>
            <CardContent>
              <Visible lg xl>
                <TextTruncate line={3} text={content} />
              </Visible>
            </CardContent>

            <CardButton to={buttonLink}>Read More</CardButton>
          </div>
        </Card>
      </div>
    </Col>
  );
};

NewsItem.defaultProps = {
  xs: 12,
  sm: null,
  md: 4,
  lg: null,
  xl: null,
};

NewsItem.propTypes = {
  /** The date of the item */
  date: PropTypes.string.isRequired,

  /** The title of the item */
  title: PropTypes.string.isRequired,

  /** The content for the item */
  content: PropTypes.string.isRequired,

  /** The URL for the item */
  buttonLink: PropTypes.string.isRequired,

  /** The URL for image for the item */
  imageUrl: PropTypes.shape({}).isRequired,

  /** The grid column size */
  xs: PropTypes.number,

  /** The grid column size */
  sm: PropTypes.number,

  /** The grid column size */
  md: PropTypes.number,

  /** The grid column size */
  lg: PropTypes.number,

  /** The grid column size */
  xl: PropTypes.number,
};

export default NewsItem;
