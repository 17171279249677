import React from 'react';
import { css } from 'emotion';
import PropTypes from 'prop-types';

const CardContent = ({ children }) => {
  const cssContent = css`
    color: #45494e;
  `;

  return <p className={cssContent}>{children}</p>;
};

CardContent.propTypes = {
  /** The contents displayed inside the card */
  children: PropTypes.string.isRequired,
};

export default CardContent;
